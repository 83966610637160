import { InjectionToken } from '@angular/core';

export interface Environment {
  readonly production: boolean;
  readonly searchApiUrl: string;
  readonly microservicesApiUrl: string;
  readonly searchGraphQlUrl: string;
  readonly googleMapsApiKey: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>('ENVIRONMENT');
